<template>
  <div :key="storeKey">
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div class="container" >
      <div class="card main" style="font-weight:normal">
        <p class="agree">1. 유럽중고플랫폼 <a href="https://vinted.fr" taret="_blank">VINTED</a> 상품을 <a href="https://smartstore.naver.com/geek9" taret="_blank">스마트스토어</a>에서 구매할 수 있습니다.</p>
        <p class="agree">2. 구매를 원하는 VINTED 상품의 URL로 스토어 페이지를 직접 생성할 수 있습니다.</p>
        <p class="agree">3. 스마트스토어에서 신용카드로 VINTED 상품을 구매대행 하실 수 있습니다.</p>
        <p class="agree">4. 스마트스토어에서 구매시 GEEK9의 구매대행 가격에 스마트스토어 수수료가 추가됩니다.</p>
        <p class="agree">5. 스마트스토어에서 구매시 한국으로 보내는 배송비는 20,000원으로 고정</p>
      </div>
      <div class="div_title">
        <span>
          <Button
            v-on:click="visible = true"
            label="스토어페이지생성"
            raised
            size="small"
          />
        </span>
        <span>
          <InputText v-on:keyup.enter="click({type:'SELECT_STORE_UPLOAD_BY_KEYWORD', page:1, keyword:keyword})" v-model="keyword" placeholder="검색어" style="max-width:100px;" />&nbsp;
          <Button
            v-on:click="click({type:'SELECT_STORE_UPLOAD_BY_KEYWORD', page:1, keyword:keyword})"
            label="조회"
            raised
            size="small"
          />
        </span>
      </div>
      <div v-if="storeList === undefined || storeList.length === 0" class="margin10 no-result">
        검색된 결과가 없습니다.
      </div>
      <div v-else >
        <div class="card" style="text-align: center;">
          <DataTable
            v-model:expandedRows="expandedRows"
            :value="storeList"
            dataKey="IDX"
            @rowExpand="onRowExpand"
            striped
          >
            <Column header="상세" expander style="width: 5rem" />
            <Column header="사진">
              <template #body="slotProps">
                <img
                  :src="slotProps.data.IMG_URL.split(',')[0]"
                  :alt="slotProps.data.image"
                  class="shadow-lg"
                  width="64"
                />
              </template>
            </Column>
            <Column field="NAME" header="상품명">
              <template #body="slotProps">
                <a
                  :href="`${this.api.SMART_STORE_URL}${slotProps.data.CODE}`"
                  target="_blank"
                  >{{ slotProps.data.NAME }}</a
                >
              </template>
            </Column>
            <Column field="price" header="가격">
              <template #body="slotProps">
                <div style="text-align: center">
                  ₩{{ Number(slotProps.data.SELL_PRICE).toLocaleString() }}
                </div>
              </template>
            </Column>
            <Column field="COUNTRY" header="국가" style="min-width:60px;">
              <template #body="slotProps">
                <div style="text-align: center">
                  <img class="country-flag" :src="this.api.getCountryFlag(slotProps.data.COUNTRY)">
                </div>
              </template>
            </Column>
            <template #expansion="slotProps">
              <div style="background-color: #f9f9f9">
                <table class="dynamic-table" style="margin-bottom: 0px">
                  <tr style="height: 100px">
                    <td>
                      <table class="order_sub_table" style="table-layout: fixed">
                        <tbody style="text-align: left">
                          <tr tr style="height: 30px">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>IDX</div>
                                <div>{{ slotProps.data.IDX }}</div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>등록일</div>
                                <div>
                                  {{
                                    this.api.getDateByFormat(
                                      slotProps.data.REG_DATE,
                                      "YYYY.MM.DD"
                                    )
                                  }}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr tr style="height: 30px">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>상품명</div>
                                <div>
                                  {{ slotProps.data.NAME }}
                                </div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>브랜드</div>
                                <div>{{ slotProps.data.BRAND }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr style="height: 30px">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>해외가격</div>
                                <div>{{ slotProps.data.ORIGIN_PRICE }}</div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>카테고리</div>
                                <div>{{ slotProps.data.CATEGORY_NAME }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr style="height: 30px">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>해외사이트</div>
                                <div>
                                  <a :href="slotProps.data.URL" target="_blank">클릭</a>
                                </div>
                              </div>
                            </td>
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>스토어</div>
                                <div>
                                  <a :href="this.api.SMART_STORE_URL+slotProps.data.CODE" target="_blank">상품보기</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr tr style="height: 30px">
                            <td class="container_sub_style">
                              <div class="div_center order-div">
                                <div>국가</div>
                                <div>{{ slotProps.data.COUNTRY }}</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </DataTable>
          <br />
          <div class="div_center">
            <label @click="paging('prev')" class="link"> 이전 </label>
            <span style="margin: 20px">{{ page }}</span>
            <label @click="paging('next')" class="link"> 다음 </label>
          </div>
        </div>
      </div>
    </div>
    <div class="ad-container">
      <Adsense
        data-ad-client="ca-pub-6699354447815429" 
        data-ad-slot="8230887469"
        data-ad-format="horizontal"
        data-full-width-responsive="true">
      </Adsense>
    </div>
  </div>
  <Dialog v-model:visible="visible" header="상품정보" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <p style="font-size: 12px;color:red;">1. VINTED의 상품을 스마트 스토어에서 구매할 수 있습니다.</p>
    <p style="font-size: 12px;color:red;">2. 구매 원하시는 VINTED 상품의 링크와 품목을 입력해주세요.</p>
    <p style="font-size: 12px;color:red;">3. 선택해주신 품목으로 세관에 신고되니 정확하게 선택해주세요!</p>
    <Divider/>
    <label for="url">URL</label><br>
    <InputText
      style="width:100%;"
      v-model="vintedUrl"
      placeholder="Vinted 상품 URL"
    />
    <br>
    <br>
    <IftaLabel>
      <label for="cate">품목</label>
      <AutoComplete
        inputId="cate"
        v-model="category"
        :suggestions="items"
        @complete="suggest"
        placeholder="품목을 한글로 입력해서 선택해주세요"
      />
    </IftaLabel>
    <Divider/>
    <div class="div_title2">
      <span style="margin-left: auto;"> 
        <Button type="button" label="취소" severity="secondary" @click="visible = false" />&nbsp;
        <Button type="button" label="생성" @click="click({type:'store'})"/>
      </span>
    </div>
  </Dialog>
</template>

<script>
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import ssr from './components/common/ssr'
export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  components: {
    policies,
    context,
  },  
  mounted() {
    this.addJsonLd()
    this.api.setPage('store')
    this.emitter.on('storeReload', this.reload)
    this.$emit("setMenu", {page:'store'})
    this.click({type:'SELECT_STORE_UPLOAD_BY_KEYWORD', page:1, keyword:this.keyword})
  },
  unmounted() {
    this.emitter.off('storeReload', this.reload)  
  },
  setup() {
    const visible = ref(false)
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 스토어'
    const keywords = '해외 사이트의 상품을 스토어에서 신용카드로 구매할 수 있습니다.'
    const description = '해외 사이트의 상품을 스토어에서도 상품 구매가 가능합니다!'
    const geek9Url = ssr.GEEK9_URL + 'store'
    const category = ref("")
    const items = ref([])

    const storeList = ref([])
    const expandedRows = ref({})
    const onRowExpand = (event) => {
      expandedRows.value = { [event.data.IDX]: true }
    }
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description)
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const cate = ref([
      { label: '마켓' },
      { label: '스토어', route: '/store' }
    ])
    return {
      cate, addJsonLd,
      onRowExpand,
      storeList,
      expandedRows,
      category,
      items,
      visible
    }
  },
  data() { 
    return { 
      vintedUrl:'',
      categoryList: [],
      keyword:'',
      storeKey: 0,
      page: 1,
    }
  },
  methods: {
    async click(param) {
      let type = param.type
      if(type === 'search') {
        console.log(type)
      } else if (type === "SELECT_STORE_UPLOAD_BY_KEYWORD") {
        this.page = param.page
        this.storeList = []
        this.emitter.emit('dialog', {flag:true})
        const keyword = param.keyword
        if(!(keyword === undefined || keyword === '')) this.keyword = keyword
        await this.search({page:this.page, keyword:this.keyword})
        .then(async (res) => {
          if (res.status === 200) {
            this.storeList = res.data
          } else
            this.emitter.emit("confirm", {
              message: "다시 시도해주세요.",
              header: "상품조회",
              icon: "pi pi-exclamation-triangle",
            })
        })
        .catch( (err) => {
          console.log(err)
        })
        .finally( ()=> {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'store') {
        if(this.vintedUrl.indexOf('vinted.') === -1 ) {
          this.emitter.emit("confirm", {
            message: "URL이 잘못되었습니다..",
            header: "상품정보",
            icon: "pi pi-exclamation-triangle",
          })
          return
        } else if(this.category === '' || this.category.indexOf('>') === -1) {
          this.emitter.emit("confirm", {
            message: "품목을 선택하지 않았습니다.",
            header: "상품정보",
            icon: "pi pi-exclamation-triangle",
          })
          return
        } else {
          this.vintedUrl = this.vintedUrl.split('?')[0]
          let flag = false
          let code
          await this.api.axiosGql('SELECT_STORE_UPLOAD_BY_URL', {
            type: 'SELECT_STORE_UPLOAD_BY_URL',
            url: this.vintedUrl,
          })
          .then(async (r) => {
            if(r.status === 200) {
              flag = true
              this.visible = false
              this.emitter.emit('dialog', {flag:false})
              if(r.data !== '') {
                code = r.data
                this.search({type:'SELECT_STORE_UPLOAD_BY_KEYWORD', page:1, keyword:code})
                this.emitter.emit("confirm", {
                  message: `등록되었습니다 : ${this.api.SMART_STORE_URL}${code}`,
                  header: "등록",
                  icon: "pi pi-info-circle"
                })
              }
            }
          })
          .catch(() => {})
          if(flag === true) return
          this.emitter.emit('dialog', {flag:true, title:'loading', page:'store'})
                
          await this.api.axiosGql(type, {
            type: type,
            brand: '',
            site: 'smartstore',
            url: this.vintedUrl,
            size: '',
            productType: 'WEAR',
            stockQuantity: 1,
            saleType: 'OLD',
            extraTitle: '',
            deliveryPrice: 20000,
            categoryTotalName: this.category.split("-")[0],
            categoryId: this.category.split("-")[1],
            deliveryCompany: "EPOST",
          })
          .then(async (result) => {
            if (result.status === 200) {
              const productNo = result.data
              const url = `${this.api.SMART_STORE_URL}${productNo}`
              
              if((url === undefined || url === '' || url === null)) {
                this.emitter.emit("confirm", {
                  message: "다시 시도해주세요.",
                  header: "등록",
                  icon: "pi pi-exclamation-triangle",
                })
              } else {
                this.emitter.emit("confirm", {
                  message: `등록되었습니다 : ${url}`,
                  header: "등록",
                  icon: "pi pi-info-circle"
                })
                this.click({type:'SELECT_STORE_UPLOAD_BY_KEYWORD', page:1, keyword:this.keyword})
              }
            } else {
              this.emitter.emit("confirm", {
                message: "다시 시도해주세요.",
                header: "등록",
                icon: "pi pi-exclamation-triangle",
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.visible = false
            this.emitter.emit('dialog', {flag:false, title:'loading'})
          })
        }
      }
    },
    async search(param) {
      const page = param.page
      const keyword = param.keyword
      return this.api.axiosGql("SELECT_STORE_UPLOAD_BY_KEYWORD", {
        type: "SELECT_STORE_UPLOAD_BY_KEYWORD",
        page: page,
        keyword: keyword.trim()
      })
    },
    paging(type) {
      if (type === "prev") {
        if (this.page === 1) {
          alert("첫 페이지입니다.")
          return
        } else this.page -= 1
      } else this.page += 1
      this.emitter.emit('dialog', {flag:true})
      this.search({page:this.page, keyword:this.keyword})
      .then(async (res) => {
        if (res.data.length === 0) {
          this.page -= 1
          alert("마지막 페이지입니다.")
        } else {
          this.storeList = res.data
        }
      })
      .catch((err) => {
        console.log(err)
        if (type === "prev") this.page += 1
        else this.page -= 1
      })
      .finally( ()=> {
        this.emitter.emit('dialog', {flag:false})
      })
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.$emit("setMenu", {page:'store'})
        this.storeKey++
      }
    },
    async suggest(event) {
      if (this.categoryList.length === 0) {
        const type = "SELECT_STORE_CATE"
        await this.api.axiosGql(type, { type: type, storeType: this.regSite })
        .then(async (res) => {
          const json = res.data
          if (res.status !== 200 || json === undefined || json.length === 0)
            alert("카테고리를 읽어 오지 못했습니다")
          else
            this.categoryList = json
            .map((item) =>  `${item.TOTAL_NAME}-${item.ID}`)
            .join(",")
        })
      }
      this.items = this.categoryList
        .split(",")
        .filter((item) => item.includes(event.query))
    },
  }
}
</script>

<style scoped>
:deep(.p-autocomplete) {
  width: 100%;
}
:deep(.p-autocomplete-input) {
  width: 100%;
}
</style>